@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Playfair+Display:ital,wght@0,400;0,800;1,400&family=Vast+Shadow&display=swap);
.midnight {
  background-color: #001f3f; /* Deep Blue */
  color: #ffffff;
}

.midnight h4, .midnight h5 {
  color: #c1c1c1;
}

.midnight .estimate {
  color: #c1c1c1;
}

.dark {
  background-color: #1f1f1f;
  color: #ffffff;
}

.dark h4, .dark h5 {
  color: #c1c1c1;
}

.dark .estimate {
  color: #c1c1c1;
}

.sepia {
  background-color: #FBF0D9;
  color: #463d31;
}

.white {
  background-color: #fff;
  color: #1f1f1f;
}

body {
  margin: 12px 0 0 0;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  width: 60%;
  margin: 0 auto;
}

.author {
  margin: 8px 0 48px 0;
}

p {
  line-height: 32px;
  font-family: serif;
  font-size: 18px;
  margin: 24px 0;
}

h1 {
  font-size: 48px;
  margin: 4px 0;
}
article p:first-child::first-letter {
  font-family: 'Vast Shadow', cursive;
  font-size: 214px;
  float: left;
  margin-right: 10px;
  line-height: 1;
}
h4,h5 {
  margin: 0;
  color: #555;
}

.pre {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.estimate {
  margin: 0;
  color: #777;
  font-size: 12px;
  font-family: 'Playfair Display', serif;
}

.logo {
  display: flex;
  position: relative;
  cursor: pointer;
}

.cant-sleep-logo {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
}

.theme-selector {
  position: absolute;
  top: 3rem;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.theme-selector .themes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.theme-selector .themes > div {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.theme-selector .themes .white {
  border: 1px solid #eee;
}

.theme-selector .themes > div:last-child {
  margin-right: 0;
}

/* .next-story {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
} */

.next-story {
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;

}

.next-story-btn {
  background: #c2c2c2;
  color: #1f1f1f;
  font-size: 0.75rem;
  font-weight: bold;
  padding: .75rem 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.white .next-story-btn {
  background: #1f1f1f;
  color: #fff;
}

.sepia .next-story-btn {
  background: #463d31;
  color: #FBF0D9;
}

@media only screen and (max-width: 600px) {
  .content {
    width: 80%;
  }

  h1 {
    font-size: 32px;
  }

  article p:first-child::first-letter {
    font-size: 112px;
  }
}
